/**
 * Environments at build time
 */
import env from 'env-var'

// ensure variables are set during cdk deploy
export const buildEnvironments = () => ({
  CMS_CACHE_TTL: env.get('CMS_CACHE_TTL').required().asString(),
  CMS_URL: env.get('CMS_URL').required().asString(),
  CMS_IDENTIFIER: env.get('CMS_IDENTIFIER').required().asString(),
  CMS_IDENTIFIER_PASSWORD: env
    .get('CMS_IDENTIFIER_PASSWORD')
    .required()
    .asString(),
  DD_API_KEY: env.get('DD_API_KEY').required().asString(),
  DISABLE_BASIC_AUTH: env.get('DISABLE_BASIC_AUTH').required().asString(),
  SITEMAP_BASE_URL: env
    .get('NEXT_PUBLIC_SITEMAP_BASE_URL')
    .required()
    .asString(),
  NEXT_PUBLIC_APP_MEDIA_CDN_URL: env
    .get('NEXT_PUBLIC_APP_MEDIA_CDN_URL')
    .required()
    .asString(),
  NEXT_PUBLIC_BACKEND_URL: env
    .get('NEXT_PUBLIC_BACKEND_URL')
    .required()
    .asString(),
  NEXT_PUBLIC_CMS_MEDIA_CDN_URL: env
    .get('NEXT_PUBLIC_CMS_MEDIA_CDN_URL')
    .required()
    .asString(),
  NEXT_PUBLIC_CONSOLE_TRACKER_ENABLED: env
    .get('NEXT_PUBLIC_CONSOLE_TRACKER_ENABLED')
    .required()
    .asString(),
  NEXT_PUBLIC_EXTERNAL_MEDIA_CDN_URL: env
    .get('NEXT_PUBLIC_EXTERNAL_MEDIA_CDN_URL')
    .required()
    .asString(),
  NEXT_PUBLIC_GTM_ID: env.get('NEXT_PUBLIC_GTM_ID').required().asString(),
  NEXT_PUBLIC_SMARTTRADE_MEDIA_CDN_URL: env
    .get('NEXT_PUBLIC_SMARTTRADE_MEDIA_CDN_URL')
    .required()
    .asString(),
  PO_EDITOR_PROJECT_ID: env.get('PO_EDITOR_PROJECT_ID').required().asString(),
  SENTRY_DSN: env.get('SENTRY_DSN').required().asString(),
  SENTRY_AUTH_TOKEN: env.get('SENTRY_AUTH_TOKEN').required().asString(),
  WEBSITE_BACKEND_AUTH_TOKEN: env
    .get('WEBSITE_BACKEND_AUTH_TOKEN')
    .required()
    .asString(),
  WEBSITE_BACKEND_CACHE_TTL: env
    .get('WEBSITE_BACKEND_CACHE_TTL')
    .required()
    .asString(),
  DEFAULT_TTL: env.get('DEFAULT_TTL').required().asString(),
  APP_MEDIA_S3_URL: env.get('APP_MEDIA_S3_URL').required().asString(),
  SMART_TRADE_MEDIA_S3_URL: env
    .get('SMART_TRADE_MEDIA_S3_URL')
    .required()
    .asString(),
  EXTERNAL_MEDIA_S3_URL: env.get('EXTERNAL_MEDIA_S3_URL').required().asString(),
  ONE_TRUST_SCRIPT_ID: env.get('ONE_TRUST_SCRIPT_ID').required().asString(),
  AUTH0_CLIENT_ID: env.get('AUTH0_CLIENT_ID').required().asString(),
  AUTH0_CLIENT_SECRET: env.get('AUTH0_CLIENT_SECRET').required().asString(),
  AUTH0_AUDIENCE: env.get('AUTH0_AUDIENCE').required().asString(),
  AUTH0_ISSUER_BASE_URL: env.get('AUTH0_ISSUER_BASE_URL').required().asString(),
  LOKI_HOST: env.get('LOKI_HOST').required().asString(),
  NEXT_PUBLIC_WEGLOT_API_KEY: env
    .get('NEXT_PUBLIC_WEGLOT_API_KEY')
    .required()
    .asString(),
  NEXT_PUBLIC_GA_MEASUREMENT_ID: env
    .get('NEXT_PUBLIC_GA_MEASUREMENT_ID')
    .required()
    .asString(),
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: env
    .get('NEXT_PUBLIC_GOOGLE_MAPS_API_KEY')
    .required()
    .asString(),
  GEO_IP_LICENSE_KEY: env.get('GEO_IP_LICENSE_KEY').required().asString(),
  GEO_IP_ACCOUNT_ID: env.get('GEO_IP_ACCOUNT_ID').required().asString(),
  CMS_TOKEN: env.get('CMS_TOKEN').required().asString(),
})

// Used and checked at runtime. listed env vars is combination of build time vars and var fetched from secret manager
export const environment = {
  SITEMAP_BASE_URL: process.env.NEXT_PUBLIC_SITEMAP_BASE_URL,
  APP_MEDIA_CDN_URL: process.env.NEXT_PUBLIC_APP_MEDIA_CDN_URL,
  BACKEND_URL: process.env.NEXT_PUBLIC_BACKEND_URL,
  CMS_IDENTIFIER: process.env.CMS_IDENTIFIER,
  CMS_IDENTIFIER_PASSWORD: process.env.CMS_IDENTIFIER_PASSWORD,
  CMS_CACHE_TTL: process.env.CMS_CACHE_TTL,
  CMS_MEDIA_CDN_URL: process.env.NEXT_PUBLIC_CMS_MEDIA_CDN_URL,
  CMS_URL: process.env.CMS_URL,
  CONSOLE_TRACKER_ENABLED: process.env.NEXT_PUBLIC_CONSOLE_TRACKER_ENABLED,
  DISABLE_BASIC_AUTH: process.env.DISABLE_BASIC_AUTH,
  EXTERNAL_MEDIA_CDN_URL: process.env.NEXT_PUBLIC_EXTERNAL_MEDIA_CDN_URL,
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  PO_EDITOR_PROJECT_ID: process.env.PO_EDITOR_PROJECT_ID,
  REDIS_URL: process.env.REDIS_URL,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SMARTTRADE_MEDIA_CDN_URL: process.env.NEXT_PUBLIC_SMARTTRADE_MEDIA_CDN_URL,
  WEBSITE_BACKEND_AUTH_TOKEN: process.env.WEBSITE_BACKEND_AUTH_TOKEN,
  WEBSITE_BACKEND_CACHE_TTL: process.env.WEBSITE_BACKEND_CACHE_TTL,
  DEFAULT_TTL: process.env.DEFAULT_TTL,
  APP_MEDIA_S3_URL: process.env.APP_MEDIA_S3_URL,
  SMART_TRADE_MEDIA_S3_URL: process.env.SMART_TRADE_MEDIA_S3_URL,
  EXTERNAL_MEDIA_S3_URL: process.env.EXTERNAL_MEDIA_S3_URL,
  ONE_TRUST_SCRIPT_ID: process.env.ONE_TRUST_SCRIPT_ID,
  LOG_EXPERIMENTS: process.env.NEXT_PUBLIC_LOG_EXPERIMENTS,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  AUTH0_CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
  AUTH0_ISSUER_BASE_URL: process.env.AUTH0_ISSUER_BASE_URL,
  AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
  LOKI_HOST: process.env.LOKI_HOST,
  WEGLOT_API_KEY: process.env.NEXT_PUBLIC_WEGLOT_API_KEY,
  GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  GEO_IP_LICENSE_KEY: process.env.GEO_IP_LICENSE_KEY,
  GEO_IP_ACCOUNT_ID: process.env.GEO_IP_ACCOUNT_ID,
  CMS_TOKEN: process.env.CMS_TOKEN,
  MACHINE_SEARCH_URL: process.env.NEXT_PUBLIC_MACHINE_SEARCH_URL,
  // TODO list datadog env var needed for tracing
}
